import React, { ReactNode, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperClass from 'swiper/types/swiper-class'
import SwiperCore, {
   EffectFade,
   Navigation,
   Pagination,
   Autoplay,
   Scrollbar,
} from 'swiper'

import 'swiper/swiper.scss'
import './main-slider.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

SwiperCore.use([Navigation, Pagination, Autoplay, Scrollbar])

interface MainSliderProps {
   spaceBetween: number
   slidesPerView: number
   children?: ReactNode
   fade?: boolean
   title?: ReactNode
   className?: string
   loading?: ReactNode
   delay?: number
   autoplay?: boolean | any
   style?: React.CSSProperties
   onSlideChange?: () => void
   centeredSlides?: boolean
   loop?: boolean
   autoHeight?: boolean
   scrollbar?: boolean
   breakpoints?: any
   initialSlide?: number
   navigation?: boolean
}

// Forward the ref to the Swiper component
const MainSlider = React.forwardRef<SwiperClass, MainSliderProps>((props, ref) => {
   const {
      spaceBetween,
      slidesPerView,
      children,
      fade,
      className,
      autoplay = false,
      delay,
      loading,
      centeredSlides,
      style,
      onSlideChange,
      loop,
      autoHeight,
      scrollbar = false,
      navigation = true,
      initialSlide = 0,
      breakpoints = null,
   } = props

   let hasArrow = children ? true : false
   if (!navigation) hasArrow = false

   return (
      <Swiper
         ref={ref} // Pass the ref down to the Swiper component
         slidesPerView={slidesPerView}
         spaceBetween={spaceBetween}
         className={className}
         effect={fade ? 'fade' : 'slide'}
         autoplay={autoplay && { delay, disableOnInteraction: true, pauseOnMouseEnter: false }}
         pagination={{ clickable: true }}
         navigation={hasArrow}
         style={style}
         onSlideChange={onSlideChange}
         centeredSlides={centeredSlides}
         loop={loop}
         autoHeight={autoHeight}
         initialSlide={initialSlide}
         breakpoints={breakpoints}
         scrollbar={scrollbar}
      >
         {children}
      </Swiper>
   )
})

export { SwiperSlide }
export default MainSlider
