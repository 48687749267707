import './cart-payment-tile.scss'
import { Currency } from '@/services/helpers/currency'
import useCartContext from '../useCartContext'
import { useEffect } from 'react'
import { checkFreeShipping } from '../../Checkout/checkFreeShipping'
import { CloseButton } from 'react-bootstrap'

export const CartTotal = () => {
  const [cart, cartDispatch, { removeSubscription }] = useCartContext()
  const {
    prices,
    extentionAttr,
    mstRewardPoints,
    applied_mw_gift_cards,
    subscription_plan,
  } = cart
  const totalDiscount =
    prices &&
    prices.discounts &&
    prices.discounts.reduce((initialCount, val) => {
      return val.amount.value + initialCount
    }, 0)
  return (
    <div className={'CartTotal'}>
      <div className="d-flex justify-content-between w-100">
        <div className="total-price-title">Total Bill: </div>
        <div className="total-price-count">
          {' '}
          <span>{`${Currency({
            money: checkFreeShipping(extentionAttr, prices),
          })}`}</span>
        </div>
      </div>
    </div>
  )
}

export const CartPaymentTile = () => {
  const [cart, cartDispatch, { removeSubscription }] = useCartContext()
  const {
    prices,
    extentionAttr,
    mstRewardPoints,
    applied_mw_gift_cards,
    subscription_plan,
  } = cart
  const totalDiscount =
    prices &&
    prices.discounts &&
    prices.discounts.reduce((initialCount, val) => {
      return val.amount.value + initialCount
    }, 0)
  return (
    <>
      {/* {console.log({ cart })} */}
      {prices && (
        <div className={'CartPaymentTile mb-4'}>
          <h3 className={'mb-3'}>Payment Details</h3>
          <table className="table pricing-table">
            <tbody>
              <tr>
                <td>Total value</td>
                <td className="td-right">
                  {`${Currency({
                    money: prices && prices.subtotal_including_tax.value,
                  })}`}
                </td>
              </tr>

                {/* condition to hide discount row when value is 0 and plan trial added */}
              {prices && prices.subtotal_including_tax?.value !== 0 && subscription_plan?.sku !== "plan-trial" && prices.discounts &&(
                <tr>
                  <td>Discount</td>
                  <td className="td-right">
                    {totalDiscount && (
                      <div className="primary-color">{`-${Currency({
                        money: totalDiscount,
                      })}`}</div>
                    )}
                  </td>
                </tr>
              )}
{/* when the sku product is != plan trial shipping would work as expected */}
 {prices && prices.subtotal_including_tax?.value !== 0 && subscription_plan?.sku !== "plan-trial" && (
  <tr>
    <td>Shipping Fee</td>
    <td className="td-right">
      {extentionAttr && extentionAttr.cart_shipping_method == 'false'
        ? `${Currency({ money: prices.shipping_flat_rate })}`
        : 'Free'}
    </td>
  </tr>
)}

              {mstRewardPoints.spend_points != 0 && (
                <tr>
                  <td>Health Points</td>
                  <td className="td-right">
                    {mstRewardPoints.spend_points != 0 && (
                      <div>
                        {`${Currency({
                          money: mstRewardPoints?.spend_points,
                        })}`}
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {applied_mw_gift_cards?.length && (
                <tr>
                  <td>Gift Card</td>
                  <td className="td-right">
                    {`${applied_mw_gift_cards[0].applied}`}
                  </td>
                </tr>
              )}

              {subscription_plan?.discount && (
                <tr className={'line-item'}>
                  <td>
                    Subscription discount ({subscription_plan.discount}%) <br />
                  </td>
                  <td className="td-right">
                    {Currency({ money: subscription_plan.discount_amount })}
                  </td>
                </tr>
              )}
              {subscription_plan && (
                <tr className={'line-item'}>
                  <td>
                    <span className="bold-text">Subscription plan</span>
                    <CloseButton
                      type="button"
                      onClick={removeSubscription}
                      className="text-danger"
                    />
                  </td>
                  <td className="td-right">
                    {Currency({ money: subscription_plan.price })}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className={'total h5 fw-bold'}>
            <div className={'title '}>Total Bill:</div>
            <div className="">
              {`${Currency({
                money: checkFreeShipping(extentionAttr, prices),
              })}`}
            </div>
          </div>
          <small>MRP inclusive of all taxes</small>
        </div>
      )}
    </>
  )
}
