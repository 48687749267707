export const GCAPTCH_SITE_KEY = process.env.APP_GCAPTCH_SITE_KEY
export const GCAPTCH_SECRET_KEY = process.env.APP_GCAPTCH_SECRET_KEY

export const SUBSCRIPTION_PRODUCTS_SKUS = [
   'plan-trial',
   'plan-silver',
   'plan-gold',
   'plan-platinum',
]

export const FEAT_SUBSCRPTION_ACTIVE = true
