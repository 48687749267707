import {
   createContext,
   useContext,
   useEffect,
   useMemo,
   useReducer,
   useRef,
} from 'react'
import { getCartId, setCartId } from '@/utils/cart'
import { getToken } from '@/utils/auth'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import { gql } from 'graphql-request'
import { useAppContext } from '@/core/App/AppContext'
import { handle_out_of_stock } from 'src/services/loadOnSession'
import { fBAddToCart } from 'src/services/fbEvents'
import { aPIEvent, getError } from 'src/services/ga'
import { removeFromCartEvent, sendAddToCartEvent } from 'src/services/gtm'
import we from 'src/services/webengage'
import addSubscriptionToCart from 'src/services/cart/addSubscriptionToCart'
import removeSubscriptionFromCart from 'src/services/cart/removeSubscriptionFromCart'
import { useState } from 'react'
import { SUBSCRIPTION_PRODUCTS_SKUS } from 'src/config'

const Context = createContext<any>({})
export enum CartActions {
   TOGGLE_MINI_CART = 'TOGGLE_MINI_CART',
   RELOAD = 'CART_RELOAD',
   TOGGLE_LOADING = 'CART_TOGGLE_LOADING',
   RESET = 'CART_RESET',
   UPDATE = 'CART_UPDATE',
}

const isFreeGift = (prevItems, nextItems) => {
   const nextFreeGift = (nextItems.items || []).find(
      (i) => i.prices.price.value == 0
   )
   const prevFreeGift = (prevItems.items || []).find(
      (i) => i.prices.price.value == 0
   )
   const freeGiftStatus =
      Boolean(nextFreeGift) && !Boolean(prevFreeGift) && prevItems.loaded == true
         ? 'added'
         : !Boolean(nextFreeGift) && Boolean(prevFreeGift)
            ? 'removed'
            : null
   return freeGiftStatus
}

const hasOnlyGiftCard = (state) => {
   if (state.items) {
      return {
         hasOnlyGiftCard:
            (state.items || []).filter(
               (item) => item.__typename != 'MageWorxGiftCardsCartItem'
            ).length == 0,
      }
   }
   return {}
}

function reducer(state, action) {
   switch (action.type) {
      case CartActions.TOGGLE_MINI_CART:
         return {
            ...state,
            miniCart: action.payload,
         }

      case 'addProductToCart':
         return {
            ...state,
            ...action.payload,
         }

      case CartActions.RELOAD:
         return {
            ...state,
            ...action.payload,
            ...hasOnlyGiftCard(action.payload),
            loaded: true,
            loading: false,
         }

      case CartActions.TOGGLE_LOADING:
         return {
            ...state,
            loading: action.payload,
         }
      case CartActions.UPDATE:
         return {
            ...state,
            ...action.payload,
            loaded: true,
         }
      case CartActions.RESET:
         return {
            ...initialState,
            loaded: true,
         }
      default:
         return state
   }
}

const initialState = {
   openMiniCart: false,
   loaded: false,
   loading: false,
   items: [],
}

function init(initialState) {
   return initialState
}

const getItemRemovedOrAdded = (prevState, newState) => {
   if (!prevState.loaded) return [[], []]
   let itemMap = (prevState.items || [])
      .filter((item) => Boolean(item.uid))
      .reduce((acc, item) => {
         acc[item.uid] = item
         return acc
      }, {})

   let addedItems = []
   for (let item of (newState.items || []).filter((item) => Boolean(item.uid))) {
      let oldItem = itemMap[item.uid]
      if (!oldItem) {
         addedItems.push(item)
      } else {
         delete itemMap[item.uid]
      }
   }

   let removedItems = Object.values(itemMap || {})
   return [addedItems, removedItems]
}

export const CartContextProvider = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState, init)
   const [isPending, setIsPending] = useState(false)
   const [isRemoveCouponPending, setIsRemoveCouponPending] = useState(false)
   const [{ mitter }] = useAppContext()
   const loadingRef = useRef(false)
   const updateRef = useRef(false)
   useEffect(() => {
      const onStart = () => {
         dispatch({
            type: CartActions.RESET,
         })
      }
      mitter.on('LOGOUT', onStart)
      return () => {
         mitter.off('LOGOUT', onStart)
      }
   }, [])

   const value = useMemo(() => {
      const getQuoteId = async () => {
         var cartId = getCartId()
         const token = getToken()
         if (!cartId) {
            cartId = await (token
               ? graphqlWebClient
                  .request(
                     gql`
                  {
                    customerCart {
                      id
                    }
                  }
                `
                  )
                  .then((d) => d.customerCart.id)
               : graphqlWebClient
                  .request(
                     gql`
                  mutation {
                    createEmptyCart
                  }
                `
                  )
                  .then((d) => d.createEmptyCart))

            setCartId(cartId)
         }
         return cartId
      }

      const addToCart = (item, product) => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }

         loadingRef.current = true

         loadingCart()
         let date = new Date()

         return getQuoteId()
            .then((cartId) =>
               import('src/services/cart/addProductsToCart').then((d) =>
                  d.default(cartId, [item])
               )
            )
            .then((d) => {
               reloadCart(d)
               fBAddToCart(d, state)
               sendAddToCartEvent(product, item)
               // sendAddToCartEvent(product, item)
               const productSku = product?.selected_variant?.sku || product?.sku
               we.event('Add To Cart', {
                  'Product ID': `${product.id || ''}`,
                  'Product Id': `${productSku}`,
                  'Product Name': `${product?.selected_variant?.name || product?.name
                     }`,
                  'Category Id': `${product?.categories?.map((i) => i?.uid)}`,
                  // 'Category Id': ``,
                  'Category Name': `${product?.categories?.map((i) => i?.name)}`,
                  'SubCategory Name': '',
                  'SubCategory Id': '',
                  brand: 'VEDISTRY',
                  Manufacturer: 'VEDISTRY',
                  Quantity: product?.cartItem?.quantity,
                  Sku: `${productSku}`,
                  'Retail Price': product?.price,
                  Discount: product?.amount_off,
                  Price: product?.list_price,
                  Currency: `${product?.price_range?.minimum_price?.final_price?.currency}`,
                  Size: '',
                  Color: '',
                  Image: `${product?.image?.url}`,
               })
               loadingRef.current = false
               aPIEvent('Add To Cart', 'success', date)
               if (
                  SUBSCRIPTION_PRODUCTS_SKUS.includes(productSku)
               ) {
                  let weSubEData = {
                     brand: 'website',
                     page_url: location.href,
                     plan_type: product.name,
                  }
                  we.event('Subscription button clicked', weSubEData)
               }
               return d
            })
            .catch((err) => {
               const { cart } = handle_out_of_stock(err)
               reloadCart(cart)
               loadingRef.current = false
               aPIEvent('Add To Cart', getError(err), date)
               return cart
            })
      }

      const updateCartItem = (items) => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }

         loadingRef.current = true

         loadingCart()
         let date = new Date()
         return getQuoteId()
            .then((cartId) =>
               import('src/services/cart/updateCartItems').then((d) =>
                  d.default(cartId, [items])
               )
            )
            .then((d) => {
               reloadCart(d)
               loadingRef.current = false
               we.event('Cart Updated', {
                  'Product Details': d?.items,
                  'Product Name': `${d.items.map((i) => i.product.name)}`,
                  'Total Amount': d?.prices?.grand_total?.value,
                  'No Of Products': d?.total_quantity,
               })
               aPIEvent('Update Cart Item', 'success', date)
               return Promise.resolve(d)
            })
            .catch((err) => {
               const { cart } = handle_out_of_stock(err)
               reloadCart(cart)
               loadingRef.current = false
               aPIEvent('Update Cart Item', getError(err), date)
               return Promise.resolve(cart)
            })
      }

      const addGiftCard = (items) => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }

         loadingRef.current = true

         loadingCart()
         let date = new Date()
         return getQuoteId()
            .then((cartId) =>
               import('src/services/cart/addMwGiftCardProductsToCart').then((d) =>
                  d.default(cartId, [items])
               )
            )
            .then((d) => {
               mitter.emit('ShowToaster', {
                  message: 'Gift Card Added to Card',
               })
               reloadCart(d)
               fBAddToCart(d, state)
               loadingRef.current = false
               aPIEvent('Gift Card Added to Cart', 'success', date)
               return Promise.resolve(d)
            })
            .catch((err) => {
               const { cart } = handle_out_of_stock(err)
               reloadCart(cart)
               loadingRef.current = false
               aPIEvent('Gift Card Added to Cart', getError(err), date)
               return Promise.resolve(cart)
            })
      }

      const removeItem = (cart_item_uid) => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }

         loadingRef.current = true

         loadingCart()
         let date = new Date()
         mitter.emit('NavigationStart', () => true)
         let item = state.items.find((item) => item.uid == cart_item_uid)
         return getQuoteId()
            .then((cartId) =>
               import('src/services/cart/removeItemFromCart').then((d) =>
                  d.default(cartId, cart_item_uid)
               )
            )
            .then((d) => {
               reloadCart(d)
               removeFromCartEvent([item])
               // removeFromCartEvent([item])
               const productSku =
                  item?.product?.selected_variant?.sku || item?.product?.sku
               we.event('Remove From Cart', {
                  'Product ID': `${item?.product?.id}`,
                  'Product Id': `${productSku}`,
                  'Product Name': `${item?.product?.name}`,
                  'Category Id': '',
                  'Category Name': '',
                  'SubCategory Name': '',
                  'SubCategory Id': '',
                  Brand: 'VEDISTRY',
                  Manufacturer: 'VEDISTRY',
                  Quantity: item.quantity,
                  'Retail Price': item?.prices?.price?.value,
                  Discount:
                     item?.product?.price_range?.minimum_price?.discount?.amount_off,
                  Currency: item?.prices?.price?.currency,
                  // Size: `${
                  //   item?.__typename == 'ConfigurableCartItem'
                  //     ? item?.configurable_options[0]?.value_label
                  //     : item?.product?.pack_size
                  // }`,
                  Size: '',
                  Color: '',
                  Image: `${item?.product?.image?.url}`,
               })
               loadingRef.current = false
               aPIEvent('Remove Cart Item', 'success', date)
               return Promise.resolve(d)
            })
            .catch((err) => {
               const { cart } = handle_out_of_stock(err)
               reloadCart(cart)
               loadingRef.current = false
               aPIEvent('Remove Cart Item', getError(err), date)
               return Promise.resolve(cart)
            })
            .finally(() => {
               mitter.emit('NavigationEnd', () => false)
            })
      }

      const removeCoupon = () => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }

         loadingRef.current = true
         setIsRemoveCouponPending(true)
         loadingCart()
         let date = new Date()
         return getQuoteId()
            .then((cartId) =>
               import('src/services/cart/removeCouponFromCart').then((d) =>
                  d.default(cartId)
               )
            )
            .then((d) => {
               reloadCart(d)
               loadingRef.current = false
               aPIEvent('Coupon Removed', 'success', date)
               return Promise.resolve(d)
            })
            .catch((err) => {
               aPIEvent('Coupon Removed', getError(err), date)
            })
            .finally(() => {
               setIsRemoveCouponPending(false)
            })
      }

      const applyCoupon = (coupon, { showAlert = false } = {}) => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }

         loadingRef.current = true
         setIsPending(true)

         loadingCart(true)
         let date = new Date()
         return getQuoteId()
            .then((cartId) =>
               import('src/services/cart/applyCouponToCart').then((d) =>
                  d.default(cartId, coupon)
               )
            )
            .then((d) => {
               reloadCart(d)
               // setIsPending(false)
               const discountValue =
                  d?.prices?.discounts?.length > 0
                     ? d?.prices?.discounts.reduce((res, i) => res + i.amount.value)
                     : null
               let prevValue = d?.prices?.grand_total?.value
               if (discountValue?.amount) {
                  prevValue =
                     d?.prices?.grand_total?.value + discountValue?.amount.value
               }
               we.event('Coupon Code Applied', {
                  'Cart Value Before Discount': prevValue,
                  'Cart Value After Discount': d?.prices?.grand_total?.value,
                  'Discont Amount': discountValue?.amount?.value,
                  'Coupon Code': `${coupon}`,
               })
               if (showAlert) {
                  mitter.emit('ShowToaster', { message: 'Coupon Code applied' })
               }
               aPIEvent('Coupon Applied', 'success', date)

               return Promise.resolve(d)
            })
            .catch((err) => {
               setIsPending(false)
               we.event('Coupon Code Failed', {
                  'Coupon Code': `${coupon}`,
               })
               aPIEvent('Coupon Applied', getError(err), date)
               if (showAlert) {
                  mitter.emit('ShowToaster', { message: 'Coupon Code not valid' })
               }
               return Promise.reject(err.message ?? 'Coupon Code Failed')
            })
            .finally(() => {
               loadingCart(false)
               loadingRef.current = false
               setIsPending(false)
            })
      }

      const reloadCart = (cart) => {
         //
         if (cart.items) {
            const freeGiftStatus = isFreeGift(state, cart)
            if (freeGiftStatus == 'added') {
               mitter.emit('ShowToaster', {
                  message: 'A free product is added to cart',
               })
            } else if (freeGiftStatus == 'removed') {
               mitter.emit('ShowToaster', {
                  message: 'A free product is removed from cart',
               })
            }
         }

         console.log(
            dispatch({
               type: CartActions.RELOAD,
               payload: cart,
            })
         )
      }

      const addSubscription = async (planId: string) => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }

         loadingRef.current = true
         console.log('loading')
         loadingCart()
         // let date = new Date()
         try {
            const cartId = await getQuoteId()
            const response = await addSubscriptionToCart(cartId, planId)
            console.log({ response })
            console.log(
               dispatch({
                  type: CartActions.UPDATE,
                  payload: response,
               })
            )
            return Promise.resolve(response)
            //import('src/services/cart/addSubscriptionToCart')
         } catch (error) {
         } finally {
            console.log('final')
            loadingCart(false)
            loadingRef.current = false
         }
      }
      const removeSubscription = async () => {
         if (loadingRef.current) {
            // mitter.emit("ShowToaster", {
            //   message: "Another cart operation is underprocess",
            // });
            return Promise.resolve({})
         }
         // alert('remove')
         loadingRef.current = true
         console.log('loading')
         loadingCart()
         // let date = new Date()
         try {
            const cartId = await getQuoteId()
            const response = await removeSubscriptionFromCart(cartId)
            console.log({ response })
            console.log(
               dispatch({
                  type: CartActions.UPDATE,
                  payload: response,
               })
            )
            return Promise.resolve(response)
            //import('src/services/cart/addSubscriptionToCart')
         } catch (error) {
            console.warn(error)
            // alert()
         } finally {
            console.log('final')
            loadingCart(false)
            loadingRef.current = false
         }
      }

      const resetCart = () => {
         dispatch({
            type: CartActions.RESET,
         })
      }

      const loadingCart = (loading: boolean = true) => {
         dispatch({
            type: CartActions.TOGGLE_LOADING,
            payload: loading,
         })
      }

      const isCartStockStatusEmpty = (items) => {
         if (
            items.filter((data) => data.product.stock_status == 'OUT_OF_STOCK')
               .length != 0
         ) {
            return true
         }
         return false
      }
      const isCartContainsBogoProduct = (items) => {
         if (items.filter((data) => data.product.bogo == 1).length != 0) {
            return true
         }
         return false
      }

      return [
         state,
         dispatch,
         {
            getQuoteId,
            addToCart,
            resetCart,
            removeItem,
            reloadCart,
            addGiftCard,
            updateCartItem,
            removeCoupon,
            applyCoupon,
            loadingCart,
            isPending,
            isRemoveCouponPending,
            isCartStockStatusEmpty,
            isCartContainsBogoProduct,
            addSubscription,
            removeSubscription,
         },
      ]
   }, [state, dispatch])

   return <Context.Provider value={value}>{children}</Context.Provider>
}

const useCartContext = () => useContext(Context)
export default useCartContext
